.hero {
  padding-top: 9rem;

  @media (max-width: em(992)) {
    padding-top: 6rem;
  }

  @media (max-width: em(768)) {
    padding-top: 3rem;
  }

  &__wrapper {
    display: flex;

    @media (max-width: em(1200)) {
      flex-direction: column;
    }
  }

  &__content {
    margin-right: 3rem;
    max-width: 57rem;

    @media (max-width: em(1200)) {
      max-width: initial;
    }
  }

  &__title {
    padding-bottom: 1.6rem;
  }

  &__text {
    padding-bottom: 5rem;

    p:not(:last-child) {
      padding-bottom: 1.2rem;
    }
  }

  &__items {
    display: grid;
    grid-gap: 1.5rem 3rem;
    grid-template-columns: 1fr 1fr;
    list-style: disc;
    padding-bottom: 5rem;
    padding-left: 1.5rem;

    @media (max-width: em(575)) {
      display: block;
    }
  }

  &__item {
    font-weight: 500;

    @media (max-width: em(575)) {
      &:not(:last-child) {
        margin-bottom: 1.2rem;
      }
    }
  }

  &__img {
    @media (max-width: em(1200)) {
      display: none;
    }
  }

  &__img-text {
    padding-top: 2.3rem;
    font-weight: 500;

    p:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
