.resume {
  padding-bottom: 14rem;

  @media (max-width: em(992)) {
    padding-bottom: 10rem;
  }

  @media (max-width: em(768)) {
    padding-bottom: 3rem;
  }

  &__title {
    word-break: break-word;
  }

  &__text {
    padding-bottom: 6rem;

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }

    p:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
  }
}
