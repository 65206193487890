.pay {
  &__subtitle {
    padding-bottom: 5rem;

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__items {
    display: grid;
    grid-gap: 2rem 13rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    padding-bottom: 6rem;

    @media (max-width: em(575)) {
      padding-bottom: 3rem;
    }
  }

  &__item {
    position: relative;
    padding-left: 3.6rem;
    font-size: fluid-font(2rem, 1.8rem);
    color: var(--clr-default-100);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 2.4rem;
      height: 2.4rem;
      background-image: url(../img/pay/done.svg);
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }

  &__link {
    display: flex;
    justify-content: center;
  }
}
