.license {

  &__title {
    word-break: break-word;
  }

  &__text {
    p:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }
}
