.header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--clr-primary-100);
  min-height: 9rem;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .banner {
    margin: 3rem auto;

    @media (max-width: em(768)) {
      margin: 1rem auto;
    }
  }
}
