.register {
  padding-bottom: 14rem;

  @media (max-width: em(992)) {
    padding-bottom: 10rem;
  }

  @media (max-width: em(768)) {
    padding-bottom: 3rem;
  }

  &__top-text {
    padding-bottom: 4rem;
  }

  &__bottom-text {
    padding-top: 5rem;
    padding-bottom: 6rem;

    @media (max-width: em(576)) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
  }
}
