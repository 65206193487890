.review {
  &__subtitle {
    padding-bottom: 6rem;

    @media (max-width: em(576)) {
      padding-bottom: 4rem;
    }
  }

  &__wrapper {
    display: grid;
    grid-column-gap: 5rem;
    grid-template-columns: 63% 33%;

    @media (max-width: em(992)) {
      display: block;
    }
  }

  &__items {
    &--pros {
      .review__item::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;
        background-image: url(../img/review/pros.svg);
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }

    &--cons {
      .review__item::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 2.4rem;
        height: 2.4rem;
        background-image: url(../img/review/cons.svg);
        background-repeat: no-repeat;
      }
    }

    @media (max-width: em(992)) {
      &:not(:last-child) {
        margin-bottom: 4rem;
      }
    }
  }

  &__item {
    position: relative;
    padding-left: 4rem;
    font-weight: 500;
    font-size: fluid-font(2rem, 1.8rem);
    line-height: 120%;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
