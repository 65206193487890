.vip {
  &__text {
    padding-bottom: 4rem;

    p:not(:last-child) {
      margin-bottom: 5rem;

      @media (max-width: em(575)) {
        margin-bottom: 3rem;
      }
    }
  }

  &__table {
    max-width: 66%;
    word-break: break-word;
    background-color: var(--clr-primary-700);

    @media (max-width: em(992)) {
      max-width: 100%;
    }
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &:not(:last-child) {
      border-bottom: 1px solid var(--clr-primary-100);
    }

    &--title {
      font-weight: 700;
      background-color: var(--clr-primary-600);
    }
  }

  &__item {
    padding-top: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 2.5rem;
    padding-left: 2.5rem;
    font-weight: 500;

    &:not(:last-child) {
      border-right: 1px solid var(--clr-primary-100);
    }

    @media (max-width: em(575)) {
      padding-top: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 1.5rem;
    }
  }
}
